import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'; 
import { useDispatch } from 'react-redux';
import { getByToken, post } from '../../api';
import { startLoading, stopLoading } from '../../redux/features/loader/loaderSlice';

const EditProfile = () => {
    const [accountInfo, setAccountInfo] = useState({
        name: '',
        email: '',
        phone: '',
        plan_name: '',
        subscriber_id: '',
        created_at: '',
        address: '',
    });
    const dispatch = useDispatch();

    useEffect(() => {
        fetchAccountInfo();
    }, [dispatch]);

    const fetchAccountInfo = async () => {
        dispatch(startLoading());
        try {
            const data = await getByToken('/account-info');
            dispatch(stopLoading());
            if (data.success) {
                setAccountInfo(data.data);
            }
        } catch (error) {
            console.error('Error fetching account info:', error);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAccountInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(startLoading());
        try {
            const response = await post('/account', accountInfo);
            if (response.success) {
                alert('Profile updated successfully!');
            } else {
                alert('Failed to update profile.');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <Container className="login-container">
            <Row className="justify-content-md-center">
                <div className="form-container form_padding">
                    <h2 className="my-4">Edit Profile</h2>
                    <Form onSubmit={handleSubmit}>
                        <Col md={12} sm={12} xs={12}>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="subscriber_id" className="mb-4">
                                    <Form.Label>Subscriber ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subscriber_id"
                                        value={accountInfo.subscriber_id}
                                        onChange={handleChange}
                                        placeholder="Subscriber ID"
                                        className="custom-disabled"
                                        disabled 
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="email" className="mb-4">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={accountInfo.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        className="custom-disabled"
                                        disabled 
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="plan" className="mb-4">
                                    <Form.Label>Plan</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="plan_name"
                                        value={accountInfo.plan_name}
                                        onChange={handleChange}
                                        placeholder="Current Plan"
                                        className="custom-disabled"
                                        disabled 
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="created_at" className="mb-4">
                                    <Form.Label>Account Created</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="created_at"
                                        value={new Date(accountInfo.created_at).toLocaleDateString()}
                                        onChange={handleChange}
                                        className="custom-disabled"
                                        disabled 
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="name" className="mb-4">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={accountInfo.name}
                                        onChange={handleChange}
                                        placeholder="Enter your name"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="phone" className="mb-4">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={accountInfo.phone}
                                        onChange={handleChange}
                                        placeholder="Enter your phone number"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="address" className="mb-4">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={accountInfo.address}
                                        onChange={handleChange}
                                        placeholder="Enter your address"
                                    />
                                </Form.Group>
                            </Col>

                            <Form.Group className="mb-4">
                                <Button variant="primary" type="submit" className='login_btn'>
                                    Update Profile
                                </Button>
                            </Form.Group>
                        </Row>
                        </Col>
                    </Form>
                </div>
            </Row>
        </Container>
    );
};

export default EditProfile;
