import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getByToken } from '../../api';
import check from "../../assets/images/check.png";
import { startLoading, stopLoading } from '../../redux/features/loader/loaderSlice';
import PlanModal from './planModal/planModal';

const Plans = () => {
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchPlans();
    }, []);

    const fetchPlans = async () => {
        dispatch(startLoading());
        try {
            const data = await getByToken("/plans");
            if (data.success) {
                setPlans(data.data);
            }
        } catch (error) {
            console.error('Error fetching plans:', error);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleShowModal = async (plan, option) => {
        setSelectedPlan(plan);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedPlan(null);
        setShowModal(false);
    };

    return (
        <>
            <Container>
                <h2 className="my-4">Available Plans</h2>
                <Row className="row-equal-height">
                    {plans.map((plan) => (
                        <Col key={plan.id} md={6} sm={12} xs={12} className="mb-4 col-equal-height">
                            <Card className="h-100">
                                <Card.Img variant="top" className="card-img-top" src={plan.image} alt={plan.name} />
                                <Card.Body className='card_body'>
                                    <div className='card-title'>
                                        <h5>{plan.name}</h5>
                                        <button
                                            className={plan.name === "Free" ? "free-button" : "subscribe-button"}
                                            onClick={() => {
                                                if (plan.name !== "Free") {
                                                    handleShowModal(plan, plan.options[0]); 
                                                }
                                            }}
                                        >
                                            {plan.name === "Free" ? "Active" : "Subscribe"}
                                        </button>
                                    </div>
                                    <Card.Text className='plan_name'>
                                        {plan.description}
                                    </Card.Text>
                                    <div className="price_wrap">
                                        <p className='text'>Start From</p>
                                        <p className='prize'> ₹{plan.price}</p>
                                    </div>
                                    {plan.options && plan.options.length > 0 && (
                                        <div className='plan_options'>
                                            <ul>
                                                {plan.options.map((option) => (
                                                    <li key={option.id} className="plan-option-item">
                                                        <img src={check} alt='img' />
                                                        {option.plan_type}: ₹{option.value} - <em>{option.tag}</em>
                                                        <button
                                                            className="subscribe-button-light"
                                                            onClick={() => handleShowModal(plan, option)} 
                                                        >
                                                            Subscribe
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            {selectedPlan && (
                <PlanModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    plan={selectedPlan}
                />
            )}
        </>
    );
};

export default Plans;
