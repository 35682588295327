import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader-overlay">
      <Spinner animation="border" role="status" style={{ color: '#e40876' }} >
      </Spinner>
    </div>
  );
};

export default Loader;
