import React, { useState, useEffect } from 'react';
import "./Login.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from 'axios';
import { SUBSCRIBER, VALIDATE_SUBSCRIBER } from '../../api/apiEndpoints';
import { useNavigate } from 'react-router-dom'; 
import { startLoading, stopLoading } from '../../redux/features/loader/loaderSlice';
import { useDispatch } from 'react-redux';
import login_img from "../../assets/images/Login_img.png"

const Login = () => {
	const dispatch = useDispatch();
	const [macAddress, setMacAddress] = useState("Android-TV");
	const [email, setEmail] = useState(""); 
	const [step, setStep] = useState(1);  
	const [timer, setTimer] = useState(30);  
	const [otpError, setOtpError] = useState("");
	const navigate = useNavigate(); 

	const handleSubscription = async (values) => {
		dispatch(startLoading());
		try {
			const response = await axios.post(
				`${SUBSCRIBER}`,
				values,
				{
					headers: {
						'Content-Type': 'application/json',
						'secret-key': process.env.REACT_APP_SECRET_KEY,
						'User-Agent': 'Android',
						'Origin': window.location.origin,
					},
				}
			);
			setEmail(values.email); 
			setStep(2);  
			setTimer(30); 
		} catch (error) {
			console.error('Error during subscription:', error);
		} finally {
			dispatch(stopLoading()); 
		}
	};

	const handlePasscodeValidation = async (values) => {
		dispatch(startLoading());
		setOtpError("");
		try {
			const response = await axios.post(
				`${VALIDATE_SUBSCRIBER}`,
				values,
				{
					headers: {
						'Content-Type': 'application/json',
						'secret-key': process.env.REACT_APP_SECRET_KEY,
						'User-Agent': 'Android',
						'Origin': window.location.origin,
					},
				}
			);
			if(response?.data?.success === true){
			    localStorage.setItem('token', response.data.token);
			    localStorage.setItem('user', JSON.stringify(response.data.data));
			    window.location.href = '/';
			} else{
				setOtpError(response?.data?.message)
			}
		} catch (error) {
			console.error('Error during passcode validation:', error);
		} finally {
			dispatch(stopLoading()); 
		}
	};

	const formikSubscription = useFormik({
		initialValues: {
			email: "",
			device_name: "Website",          
			mac_address: macAddress,    
			android_id: "9",            
			app_version: "1.2.3",       
		},
		validationSchema: Yup.object({
			email: Yup.string().email("Invalid email address").required("Required"),
		}),
		onSubmit: handleSubscription,
	});

	const formikPasscode = useFormik({
		initialValues: {
			email: email, 
			mac_address: macAddress,
			password: "",
		},
		enableReinitialize: true, 
		validationSchema: Yup.object({
			password: Yup.string().required("Required"),
		}),
		onSubmit: handlePasscodeValidation,
	});

	const handleResendOtp = () => {
		console.log("Resend OTP clicked");
		setTimer(30); 
	};

	useEffect(() => {
		if (timer > 0) {
			const interval = setInterval(() => {
				setTimer(timer - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [timer]);

	const handleGoBack = () => {
		setStep(1);
	};

	return (
		<Container className="login-container">
			<Row className="justify-content-md-center align-items-center form-container-outer">
			<Col md={6} className='login_image'>
			   <div className='text-center heading'>
			   <h3 className="  tagline">Entertainment Redefined: Neo TV+</h3>
			   </div>
			   <div><img src={login_img} alt="" /></div>
			</Col>
				<Col md={6}>
					<div className="form-container">
						{step === 1 && (
							<>
								<h3 className="text-center heading">Sign in/Sign Up</h3>
								<p className='subheading'>Enter your email to get started !</p>
								<Form onSubmit={formikSubscription.handleSubmit}>
									<Form.Group controlId="email" className="mb-3">
										<Form.Control
											type="email"
											placeholder="Enter email"
											{...formikSubscription.getFieldProps("email")}
											isInvalid={formikSubscription.touched.email && formikSubscription.errors.email}
										/>
										<Form.Control.Feedback type="invalid">
											{formikSubscription.errors.email}
										</Form.Control.Feedback>
									</Form.Group>

									{/* Hidden fields */}
									<Form.Control
										type="hidden"
										{...formikSubscription.getFieldProps("device_name")}
									/>
									<Form.Control
										type="hidden"
										{...formikSubscription.getFieldProps("mac_address")}
									/>
									<Form.Control
										type="hidden"
										{...formikSubscription.getFieldProps("android_id")}
									/>
									<Form.Control
										type="hidden"
										{...formikSubscription.getFieldProps("app_version")}
									/>

									<Button type="submit" className='login_btn'>
										Submit
									</Button>
								</Form>
							</>
						)}

						{step === 2 && (
							<>
								<p style={{ color: '#424242', marginBottom: '20px',textAlign:"center" }}>
									We have sent an OTP on your email ({email}). Please do not forget to check your spam folder.
								</p>
								<Form onSubmit={formikPasscode.handleSubmit}>
									<Form.Group controlId="password" className="mb-3">
										<Form.Control
											type="password"
											placeholder="Enter Passcode"
											{...formikPasscode.getFieldProps("password")}
											isInvalid={formikPasscode.touched.password && formikPasscode.errors.password}
											onChange={(e) => {
												formikPasscode.handleChange(e); 
												setOtpError("");  
											}}
										/>
										<Form.Control.Feedback type="invalid">
											{formikPasscode.errors.password}
										</Form.Control.Feedback>
									</Form.Group>
									{otpError && <div className="error-message" style={{ color: 'red' }}>{otpError}</div>}
									<div className="d-flex justify-content-between mb-3">
										<span 
											style={{ cursor: 'pointer', color: '#424242' }} 
											onClick={handleGoBack}>
											Go Back
										</span>
										<span 
											style={{ cursor: timer === 0 ? 'pointer' : 'not-allowed', color: '#424242' }}
											onClick={timer === 0 ? handleResendOtp : null}>
											{timer === 0 ? 'Resend OTP' : `Resend OTP in ${timer}s`}
										</span>
									</div>
									<Button type="submit" className='login_btn'>
										Validate Passcode
									</Button>
								</Form>
							</>
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Login;
