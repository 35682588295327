import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const PrivateRoute = ({ children }) => {
	const { auth ,logout} = useAuth();
	const navigate = useNavigate();


	useEffect(() => {
		let logoutTimer;
		if (auth.token) {
		  logoutTimer = setTimeout(() => {
			logout();
			navigate('/login');
		  }, 1200000); 
		}
		return () => {
		  clearTimeout(logoutTimer); 
		};
	  }, [auth.token, logout]);


	if (!auth.token) {
		return <Navigate to="/login" replace />;
	}
	return children;
};

export default PrivateRoute;
