import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrow_right from '../../assets/icons/arrow_right.svg';

const AccountButton = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const handleButtonClick = () => {
        if (token) {
            navigate('/profile');
        } else {
            navigate('/login');
        }
    };
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login')
        window.location.href = '/login';
    };
    return (
        <>
            <div className="btn_div">
                {token ?
                   <>  
                       <button className="acc_btn" onClick={handleLogout}>
                           <p>LOGOUT</p>
                           <div className="arrow_icon">
                               <img src={arrow_right} alt="arrow icon" />
                           </div>
                       </button>
                    </> :
                    <>
                        <button className="acc_btn" onClick={handleButtonClick}>
                            <p>LOGIN</p>
                            <div className="arrow_icon">
                                <img src={arrow_right} alt="arrow icon" />
                            </div>
                        </button>
                    </>
                }
            </div>
        </>
    );
};

export default AccountButton;
