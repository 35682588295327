import axios from 'axios';
import { API_BASE_URL } from './apiEndpoints';

const secretKey = process.env.REACT_APP_SECRET_KEY;
const origin = window.location.origin;

export const API = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'secret-key': secretKey,
        'Origin': origin,  
    },
});

let token = localStorage.getItem('token');
export const API_BY_TOKEN = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Authorization': 'Bearer ' + token,
        'Origin': origin,  
    },
});

API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



export const get = async (url, params = {}) => {
    try {
        const response = await API.get(url, { params });
        return response.data;
    } catch (error) {
        console.error('GET request failed:', error);
        throw error;
    }
};

export const getByToken = async (url, params = {}) => {
    try {
        const response = await API_BY_TOKEN.post(url, { params });
        return response.data;
    } catch (error) {
        console.error('GET request failed:', error);
        throw error;
    }
};


export const post = async (url, data) => {
    try {
        const response = await API_BY_TOKEN.post(url, data);
        return response.data;
    } catch (error) {
        console.error('POST request failed:', error);
        throw error;
    }
};

export const put = async (url, data) => {
    try {
        const response = await API.put(url, data);
        return response.data;
    } catch (error) {
        console.error('PUT request failed:', error);
        throw error;
    }
};

export const del = async (url) => {
    try {
        const response = await API.delete(url);
        return response.data;
    } catch (error) {
        console.error('DELETE request failed:', error);
        throw error;
    }
};


