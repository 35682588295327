import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.scss";
import googlePlayLogo from "../../assets/images/play-store.png";
import fireTvLogo from "../../assets/images/firetv.png";
import liveTvLogo from "../../assets/images/khabriya_logo.png";
import headset from "../../assets/images/headset.png";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="row_margin">
          <Col md={3} sm={6} className="mb-4">
            <div className="contact-info">
              <div className="support_image">
                <img src={headset} alt="Mail Icon" className="icon" />
              </div>
              <div>
                <p>Email Us</p>
                <a href="mailto:help@khabriya.in">help@neotvapp.com</a>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="https://neotvapp.com/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="https://neotvapp.com/terms">Terms & Conditions</a>
              </li>
              <li>
                <a href="https://neotvapp.com/cancellation">Cancellation & Refund Policy</a>
              </li>
              <li>
                <a href="https://neotvapp.com/contact">Contact</a>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <h5>Company</h5>
            <ul className="list-unstyled">
              <li>
                <a href="https://neotvapp.com">Home</a>
              </li>
              <li>
                <a href="https://neotvapp.com/about">About Company</a>
              </li>
              <li>
                <a href="https://neotvapp.com/contact">Contact Us</a>
              </li>
              <li>
                <a href="https://neotvapp.com/blog">Blog</a>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={6} className="mb-4 ">
            <h5>Available On</h5>
            <img src={googlePlayLogo} alt="Google Play" className="footer_store_image" />
            <img src={fireTvLogo} alt="Fire TV Stick" className="footer_store_image" />
          </Col>
        </Row>
        {/* <Row className="footer-bottom">
          <Col md={12} className="text-center">
            <img src={liveTvLogo} alt="Live TV" className="footer-logo" />
            <p>&copy; 2024 | A Khabriya Digital Media Company Product.</p>
          </Col>
        </Row> */}
      </Container>
    </footer>
  );
};

export default Footer;
