import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState({
		token: localStorage.getItem('token')|| null,
		user: JSON.parse(localStorage.getItem('user'))|| null,
	});

	const login = (token, user) => {
		setAuth({ token, user });
		localStorage.setItem('token', token);
		localStorage.setItem('user', JSON.stringify(user));
	};

	const logout = () => {
		setAuth({ token: null, user: null });
		localStorage.removeItem('token');
		localStorage.removeItem('user');
	};

	return (
		<AuthContext.Provider value={{ auth, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
