import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../assets/images/neo-tv-logo-b.png";
import mail from "../../assets/icons/mail.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AccountButton from "../accountButton/accountButton";
import { Dropdown } from "react-bootstrap";
import hamburger from "../../assets/images/hamburger.png";
import close_icon from "../../assets/images/close-window.png";
import upIcon from "../../assets/images/arrow-up.png";
import downIcon from "../../assets/images/down.png";

const Header = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState({})


  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
        setUserData(JSON.parse(user));  
    }
}, []); 

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="main">
      <div className={!userData.name && !userData.subscriber_id ? "logo top_position" : "logo" } onClick={handleClick}>
        <img src={logo} alt="" />
      </div>
      <div className="header">
        <div className="main_header">
          <div className="top_header">
            <div className="mail_icon">
              <img src={mail} alt="" />
            </div>
            <p>help@neotvapp.com</p>
          </div>
          <div className="bottem_header">
            
            <ul className={!userData.name && !userData.subscriber_id ? "padding_bottom" : ""}>
              <li>
              {userData.name && userData.subscriber_id  &&
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    Account
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile">
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/edit-profile">
                      Edit Profile
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
               }
              </li>
              <li>
              {userData.name && userData.subscriber_id  &&
                <Link to="/plans">Plans</Link>
              }
              </li>
              <li>
              {userData.name && userData.subscriber_id  &&
                <Link to="/history">Transaction History</Link>
              }
              </li>
            </ul>
            {userData.name && userData.subscriber_id  &&
            <div className="header_profile">
                  <div className='about'>
                   <p className="name"> {userData.name}</p>  
                   <p className="id">Subscriber ID: {userData.subscriber_id}</p>
                   </div>
              <AccountButton />
            </div>
            }
          </div>
          <div className="hamburger" onClick={toggleSidebar}>
            <img src={hamburger} alt="" />
          </div>
        </div>
      </div>
      {isSidebarOpen && (
        <div className="sidebar">
          <div className="sidebar_content">
            <div className="close_icon" onClick={toggleSidebar}>
              <img src={close_icon} alt="Close" />
            </div>
            <div className="bottem_header">
            {userData.name && userData.subscriber_id  &&
              <ul>
                <li>
                  <NavLink to="#" onClick={toggleMenu} className="menu-link">
                    Account{" "}
                    {isMenuOpen ? (
                      <img style={{ width: "10px" }} src={upIcon} />
                    ) : (
                      <img style={{ width: "10px" }} src={downIcon} />
                    )}
                  </NavLink>
                  {isMenuOpen && (
                    <ul className="submenu">
                      <li>
                        <NavLink to="/profile" onClick={toggleSidebar}>
                          Profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/edit-profile" onClick={toggleSidebar}>
                          Edit Profile
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <NavLink to="/plans" onClick={toggleSidebar}>
                    Plans
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/history" onClick={toggleSidebar}>
                    Transaction History
                  </NavLink>
                </li>
              </ul>
              }
              {userData.name && userData.subscriber_id  &&
              <div className='about'>
                   <p className="name">{userData.name}</p>  
                   <p className="id">Subscriber ID:{userData.subscriber_id}</p>
               </div>}
              <AccountButton onClick={toggleSidebar} />
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
