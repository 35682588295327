import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import Footer from './componenets/footer/Footer';
import Header from './componenets/header/Header';
import Login from './componenets/login/Login'; 
import { AuthProvider, useAuth } from './helper/AuthProvider';
import PrivateRoute from './helper/PrivateRoute';
import EditProfile from './componenets/dashboard/editProfile';
import TransactionHistory from './componenets/dashboard/transactionHistory';
import { useSelector } from 'react-redux';
import Loader from './componenets/Loader/Loader';
import Plans from './componenets/dashboard/plans';
import Profile from './componenets/dashboard/profile';

function App() {
  const isLoading = useSelector((state) => state.loader.loading); 

  return (
    <AuthProvider>
      <Router>
        <div className="App">
        {isLoading ? <Loader loading={isLoading} /> : <Routes/>}
          <Header />
          <Routes>
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} /> 
            <Route path="/edit-profile" element={<PrivateRoute><EditProfile /></PrivateRoute>} />  
            <Route path="/history" element={<PrivateRoute><TransactionHistory /></PrivateRoute>} />
            <Route path="/plans" element={<PrivateRoute><Plans /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="*" element={<Navigate to="/plans" replace />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

const PublicRoute = ({ children }) => {
  const { auth } = useAuth();
  
  if (auth.token) {
    return <Navigate to="/plans" replace />;
  }
  
  return children;
};

export default App;
