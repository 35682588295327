import React, { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';
import { getByToken } from '../../api';
import { startLoading, stopLoading } from '../../redux/features/loader/loaderSlice';
import { useDispatch } from 'react-redux';

const TransactionHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => { 
        const fetchTransactions = async () => {
            dispatch(startLoading());
            try {
                const data = await getByToken("/transactions");
                setTransactions(data.data); 
            } catch (error) {
                console.error('Failed to fetch transactions:', error);
            }finally{
                dispatch(stopLoading());
            }
        };

        fetchTransactions();
    }, []);

    return (
        <Container>
            <h2 className="my-4 ">Transaction History</h2>
            <div className="table-responsive">
                <Table striped bordered hover className="transaction-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Transaction ID</th>
                            <th>Plan Name</th>
                            <th>Plan Option</th>
                            <th>Status</th>
                            <th>Valid From</th>
                            <th>Valid To</th>
                            <th>Amount</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction, index) => {
                            // Parse the link_info JSON string
                            const linkInfo = JSON.parse(transaction.link_info);

                            return (
                                <tr key={transaction.transaction_id}>
                                    <td>{index + 1}</td>
                                    <td>{transaction.transaction_id}</td>
                                    <td>{transaction.name ? transaction.name : "Ad Free Premium"}</td>
                                    <td>{transaction.plan_option}</td>
                                    <td>{transaction.status}</td>
                                    <td>{new Date(transaction.valid_from).toLocaleString()}</td>
                                    <td>{new Date(transaction.valid_to).toLocaleString()}</td>
                                    <td>₹{linkInfo.data.link_amount}</td>
                                    <td>{new Date(transaction.created_at).toLocaleString()}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </Container>
    );
}

export default TransactionHistory;
