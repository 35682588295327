import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import './planModal.scss';
import { post } from '../../../api';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../../redux/features/loader/loaderSlice';
import payment_icon from "../../../assets/images/payment_icons.png"

const PlanModal = ({ show, handleClose, plan }) => {    
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(plan.options[0] || null); 

    const handleSubscribe = async(plan, option) => {        
        const requestBody = { plan_id: plan.id, option_id: option.id };
        dispatch(startLoading());
        try {
            const link = await post('/generate-link', requestBody);   
            if(link.data){
                window.open(link.data, '_blank');
            }
        } catch (error) {
            console.error('Error generating link:', error);
        }finally{
            dispatch(stopLoading());
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option); 
    };

    return (
        <Modal show={show} onHide={handleClose} centered className="plan-modal">
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>Subscription Plans</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="subscription-card">
                    <Row style={{alignItems:"center"}}>
                        <Col md={6}>
                            <div className="plan-header">
                                <p>Choose a plan that suits your watching preferences</p>
                            </div>
                            <div className="plan-options">
                                {plan.options.map((option) => (
                                    <div 
                                        key={option.id} 
                                        className={`plan-option ${selectedOption?.id === option.id ? 'selected' : ''}`} 
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        <div className="plan-name">{option.plan_type}</div>
                                        <div>
                                            <div className="plan-price">₹ {option.value}</div> 
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                        <Col  md={6}>
                            <div className="ad-banner">
                                <div className="ad-text">
                                    <h3>{plan.name}</h3>
                                    <p>{plan.description}</p>
                                    {selectedOption ? (
                                        <>
                                            <Button 
                                                variant="warning" 
                                                className="subscribe-btn" 
                                                onClick={() => handleSubscribe(plan, selectedOption)}
                                            >
                                                Pay Now
                                            </Button>
                                            <p className="price-tag">₹{selectedOption.value} <span>{selectedOption.tag}</span></p>
                                        </>
                                    ) : (
                                        <>
                                            <Button 
                                                variant="warning" 
                                                className="subscribe-btn" 
                                                disabled
                                            >
                                                Select a Plan
                                            </Button>
                                            <p className="price-tag">₹{plan.price} <span>per month only.</span></p>
                                        </>
                                    )}
                                    <img src={payment_icon} alt='payment-icons'/>
                                </div>
                            </div>
                            <div className="help-text">
                                <div>
                                    Ask for help - <a href="mailto:help@neotvapp.com">help@neotvapp.com</a>
                                </div>
                                {/* <div>
                                    or Visit - <a href="hhttps://neotvapp.com/help">https://neotvapp.com/help</a>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PlanModal;
