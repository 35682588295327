
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getByToken } from '../../api';
import { startLoading, stopLoading } from '../../redux/features/loader/loaderSlice';

const Profile = () => {
    const [accountInfo, setAccountInfo] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		fetchAccountInfo();
	}, []);

	const fetchAccountInfo = async () => {
		console.log('Starting loading');
		dispatch(startLoading());
		try {
			const data = await getByToken('/account-info');
			if (data.success) {
				setAccountInfo(data.data);
			}
		} catch (error) {
			console.error('Error fetching account information:', error);
		} finally {
			dispatch(stopLoading());
		}
	};

    return (
        <>
        <Container>
            <h2 className="my-4">Account Information</h2>
            {accountInfo && (
                <Row className="mb-4">
                    <Col md={12} sm={12} xs={12} >
                        <Card className='info_card'>
                            <Card.Body>
                                <Row>
                                    <Col md={6} sm={12}>
                                        <Card.Text className='margin_right'>
                                            <strong>Subscriber ID:</strong> {accountInfo.subscriber_id}
                                        </Card.Text>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card.Text className='margin_right'>
                                            <strong>Email:</strong> {accountInfo.email || "test@gmail.com"}
                                        </Card.Text>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card.Text className='margin_right'>
                                            <strong>Name:</strong> {accountInfo.name || 'N/A'}
                                        </Card.Text>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card.Text className='margin_right'>
                                            <strong>Plan:</strong> {accountInfo.plan_name} ({accountInfo.plan_type})
                                        </Card.Text>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card.Text className='margin_right'>
                                            <strong>Phone:</strong> {accountInfo.phone || 'N/A'}
                                        </Card.Text>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card.Text className='margin_right'>
                                            <strong>Account Created:</strong> {new Date(accountInfo.created_at).toLocaleDateString()}
                                        </Card.Text>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            </Container>
        </>
    );
};

export default Profile;
